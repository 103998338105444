import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro/alt'
import HomeContent from '../components/sections/home-content/advantage-supplement'
import Partners from '../components/sections/partners'
import Zip from '../components/apply/zip'

import { submitData, toggleField } from '../reducers/captive'

import Surfers from '../images/surfers.png'

const HomeA = ({ location, toggleField, submitData, captive }) => {
  useEffect(() => {
    toggleField('otherInsuranceDefault', false)
    toggleField('specialFlow', true)
    toggleField('homeOrigin', 'a')
    submitData({ other: false })

    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'landing',
      })
  }, [captive.otherInsuranceDefault, submitData, toggleField])

  useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'landing',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout
      phone="866-740-2901"
      location={location}
      footerAlt
      includeCitySearch
      showCountDown
    >
      <SEO
        title="Affordable Medicare Advantage or Medicare Supplement Plans - Medicare Companion"
        keywords={[
          `medicare`,
          `insurance`,
          `plans`,
          `affordable`,
          `supplement`,
          `advantage`,
        ]}
      />
      <Header
        phone="866-740-2901"
        className="new gray-style"
        grayLogo
        ctaText="<strong>Need Help?</strong> Speak with a <br/> Licensed Insurance Agent."
      />
      <Intro
        showTitle
        classNames="reverse small-title"
        title="Get an Affordable Medicare Advantage or Medicare Supplement Plan"
        subTitle="Get a <i>FREE</i> quote in less than 2 minutes for better coverage."
        bgImage={Surfers}
        showImgContent
        showCegeInfo
      >
        <div className="form-box">
          <div className="contacts-form alt text-center">
            <Zip
              shouldNavigate
              btnText="Start my quote"
              toggleApplyLayout
              includeCitySearch
            />
          </div>
        </div>
      </Intro>
      <main id="main">
        <Partners showTitle={false} bg="bg-dark" />
        <HomeContent phone="866-740-2901" />
      </main>
    </Layout>
  )
}

export default connect((state) => state, { submitData, toggleField })(HomeA)
